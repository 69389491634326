import React from 'react';
import './DesktopInstallPrompt.css';
import qrCode from '../assets/qrcode.png';

const DesktopInstallPrompt = () => {
  return (
    <div className="desktop-install-page">
      <div className="desktop-install-content">
        <img src="/image/logo192.png" alt="Logo" className="desktop-install-logo" />
        <h2>Application Mobile</h2>
        <p>Cette application est optimisée pour les smartphones.</p>
        <p>Scannez le QR code ci-dessous avec votre téléphone pour installer l'application :</p>
        
        <div className="qrcode-container">
          <img src={qrCode} alt="QR Code" className="qrcode-image" />
        </div>
      </div>
    </div>
  );
};

export default DesktopInstallPrompt; 