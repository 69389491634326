import React from 'react';
import './ReinstallModal.css';

const ReinstallModal = ({ onClose }) => {
  return (
    <div className="reinstall-modal-overlay">
      <div className="reinstall-modal">
        <h2>Réactiver les notifications</h2>
        <div className="steps">
          <ol>
            <li>
              Installez d'abord la nouvelle version :
              <button 
                className="install-button"
                onClick={() => window.location.href = 'https://www.hypevap.org'}
              >
                Installer la nouvelle version
              </button>
            </li>
            <li>
              Une fois installée :
              <ul>
                <li>Retournez à l'écran d'accueil</li>
                <li>Appui long sur l'ancienne version</li>
                <li>Sélectionnez "Supprimer l'application"</li>
              </ul>
            </li>
            <li>
              <strong>Important :</strong> Ouvrez la nouvelle version et acceptez les notifications au premier lancement
            </li>
          </ol>
        </div>
        <button className="close-button" onClick={onClose}>
          Fermer
        </button>
      </div>
    </div>
  );
};

export default ReinstallModal; 