import React, { useState, useEffect } from 'react';
import { db } from '../config/firebase';
import { collection, addDoc } from 'firebase/firestore';
import './Feedback.css';

const Feedback = () => {
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [cooldown, setCooldown] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    // Vérifier s'il y a un timestamp stocké
    const lastSubmitTime = localStorage.getItem('lastFeedbackSubmit');
    if (lastSubmitTime) {
      const timeElapsed = Date.now() - parseInt(lastSubmitTime);
      const remainingCooldown = Math.max(60 - Math.floor(timeElapsed / 1000), 0);
      
      if (remainingCooldown > 0) {
        setIsButtonDisabled(true);
        setCooldown(remainingCooldown);
      }
    }
  }, []);

  useEffect(() => {
    let timer;
    if (cooldown > 0) {
      timer = setInterval(() => {
        setCooldown(prev => {
          if (prev <= 1) {
            setIsButtonDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [cooldown]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isButtonDisabled) return;

    try {
      const now = new Date();
      const formattedDate = now.toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });

      await addDoc(collection(db, 'feedbacks'), {
        email,
        message: comment,
        date: formattedDate,
        read: false
      });

      // Stocker le timestamp de soumission
      localStorage.setItem('lastFeedbackSubmit', Date.now().toString());
      
      setIsSubmitted(true);
      setEmail('');
      setComment('');
      setIsButtonDisabled(true);
      setCooldown(60);
      
      setTimeout(() => {
        setIsSubmitted(false);
      }, 3000);
    } catch (error) {
      console.error("Erreur lors de l'envoi du feedback:", error);
    }
  };

  return (
    <div className="feedback-page">
      <div className="feedback-container">
        <h2>Donnez-nous votre avis</h2>
        
        <p className="feedback-description">
          Votre avis est important pour nous ! <br></br>Aidez-nous à améliorer l'application en partageant vos suggestions, 
          en signalant des bugs ou en proposant de nouvelles fonctionnalités.
        </p>

        <form onSubmit={handleSubmit} className="feedback-form">
          <input
            type="email"
            placeholder="Votre adresse email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="email-input"
          />
          <textarea
            placeholder="Partagez vos suggestions, signalez un bug ou proposez de nouvelles fonctionnalités..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            required
          />
          <button 
            type="submit" 
            className={isButtonDisabled ? 'disabled' : 'submit-button'}
            disabled={isButtonDisabled}
          >
            {isButtonDisabled 
              ? `Patienter ${cooldown} secondes` 
              : 'Envoyer'}
          </button>
        </form>
      </div>
      {isSubmitted && (
        <div className="feedback-success">
          Message envoyé avec succès !
        </div>
      )}
    </div>
  );
};

export default Feedback;