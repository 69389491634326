import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { collection, query, orderBy, onSnapshot, Timestamp } from 'firebase/firestore';
import { db } from '../config/firebase';
import './BottomNavigation.css';

const NavContainer = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(18, 18, 18, 0.85);
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  padding: var(--spacing-md);
  display: flex;
  justify-content: space-around;
  z-index: 1000;
  
  /* Support pour iOS */
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: rgba(18, 18, 18, 0.85);
  }
  
  /* Ajouter un effet de shadow subtil */
  box-shadow: 0 -1px 24px rgba(0, 0, 0, 0.08);
`;

const NavItem = styled(NavLink)`
  color: rgba(255, 255, 255, 0.65);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  transition: all var(--transition-speed) var(--transition-ease);

  &.active {
    color: var(--text-light);
  }

  &:hover {
    color: var(--text-light);
  }

  svg {
    font-size: 1.5rem;
    margin-bottom: var(--spacing-xs);
  }
`;

const BottomNavigation = () => {
  const location = useLocation();
  const [hasNewDeals, setHasNewDeals] = useState(false);

  useEffect(() => {
    const dealsQuery = query(
      collection(db, 'deals'),
      orderBy('dateCreation', 'desc')
    );

    const unsubscribe = onSnapshot(dealsQuery, (snapshot) => {
      const changes = snapshot.docChanges();
      console.log('Changements détectés:', changes.length);
      
      if (changes.length > 0) {
        const newDeals = changes.some(change => change.type === 'added');
        console.log('Nouveau deal détecté:', newDeals);
        
        if (newDeals) {
          const newestDeal = changes[0].doc.data();
          const dealTime = newestDeal.dateCreation instanceof Timestamp 
            ? newestDeal.dateCreation.toMillis()
            : new Date(newestDeal.dateCreation).getTime();
          
          const now = Date.now();
          const isRecent = now - dealTime < 24 * 60 * 60 * 1000;
          
          // Vérifier si ce deal a déjà été vu
          const lastSeenDeal = localStorage.getItem('lastSeenDeal');
          const shouldShowNotification = isRecent && (!lastSeenDeal || dealTime > parseInt(lastSeenDeal));

          if (shouldShowNotification) {
            setHasNewDeals(true);
          }
        }
      }
    });

    return () => unsubscribe();
  }, []);

  // Réinitialiser le badge quand on visite la page deals
  useEffect(() => {
    if (location.pathname === '/deals') {
      setHasNewDeals(false);
      // Sauvegarder le timestamp du dernier deal vu
      const now = Date.now();
      localStorage.setItem('lastSeenDeal', now.toString());
    }
  }, [location]);

  return (
    <nav className="bottom-nav">
      <NavLink 
        to="/news" 
        className={`nav-item ${location.pathname === '/news' || location.pathname === '/' ? 'active' : ''}`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path fillRule="evenodd" d="M12 2.25A6.75 6.75 0 005.25 9v.75a8.217 8.217 0 01-2.119 5.52.75.75 0 00.298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 107.48 0 24.583 24.583 0 004.83-1.244.75.75 0 00.298-1.205 8.217 8.217 0 01-2.118-5.52V9A6.75 6.75 0 0012 2.25zM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 004.496 0l.002.1a2.25 2.25 0 11-4.5 0z" clipRule="evenodd" />
        </svg>
        <span>Nouveautés</span>
      </NavLink>

      <NavLink 
        to="/deals" 
        className={`nav-item ${location.pathname === '/deals' ? 'active' : ''}`}
      >
        {hasNewDeals && <span className="notification-dot"></span>}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path fillRule="evenodd" d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z" clipRule="evenodd" />
        </svg>
        <span>Bons Plans</span>
      </NavLink>

      <NavLink 
        to="/shops" 
        className={`nav-item ${location.pathname === '/shops' ? 'active' : ''}`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path fillRule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
        </svg>
        <span>Boutiques</span>
      </NavLink>
    </nav>
  );
};

export default BottomNavigation; 