import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import CardNews from '../components/CardNews';
import FilterNews from '../components/FilterNews';
import './News.css';

const News = () => {
  const navigate = useNavigate();
  const [nouveautes, setNouveautes] = useState([]);
  const [filteredNouveautes, setFilteredNouveautes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);

  const fetchNouveautes = async () => {
    try {
      const q = query(
        collection(db, 'nouveautes'),
        orderBy('timestamp', 'desc')
      );
      
      const querySnapshot = await getDocs(q);
      const nouveautesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setNouveautes(nouveautesData);
      setFilteredNouveautes(nouveautesData);
      
      // Extraire les catégories uniques
      const uniqueCategories = [...new Set(nouveautesData.map(item => item.categorie))];
      setCategories(uniqueCategories);
      
      setError(null);
    } catch (err) {
      console.error("Erreur lors de la récupération des nouveautés:", err);
      setError("Erreur lors de la récupération des nouveautés");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNouveautes();
  }, []);

  const handleFilter = (category) => {
    if (category === 'all') {
      setFilteredNouveautes(nouveautes);
    } else {
      // Créer un mapping des catégories pour correspondre à la base de données
      const categoryMapping = {
        'eliquide': 'E-liquide',
        'ecigarette': 'E-cigarette',
        'cbd': 'CBD',
        'accessoire': 'Accessoire'
      };

      const filtered = nouveautes.filter(item => 
        item.categorie === categoryMapping[category]
      );
      setFilteredNouveautes(filtered);
    }
  };

  const handleNotificationClick = () => {
    navigate('/notifications');
  };

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Une erreur est survenue</div>;

  return (
    <div className="news-page">
      <FilterNews onFilterChange={handleFilter} />
      <CardNews nouveautes={filteredNouveautes} />
    </div>
  );
};

export default News; 