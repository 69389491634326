import { doc, runTransaction, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../config/firebase';
import { trackError } from './errorTracking';

// Flag global pour éviter le double tracking
let isTracking = false;

// Fonction pour formater les dates
const formatDate = (date) => ({
  dateStr: date.toISOString().split('T')[0],
  monthStr: date.toISOString().split('T')[0].substring(0, 7),
  hourStr: date.getHours().toString().padStart(2, '0')
});

// Tracking des visites
export const trackAppVisit = async (isStandalone) => {
  if (!isStandalone || isTracking) return;
  isTracking = true;

  try {
    const today = new Date();
    const { dateStr, monthStr, hourStr } = formatDate(today);
    const visitsRef = doc(db, 'analytics', 'visits');

    await runTransaction(db, async (transaction) => {
      const visitsDoc = await transaction.get(visitsRef);
      if (!visitsDoc.exists()) {
        transaction.set(visitsRef, {
          totalVisits: 1,
          dailyVisits: { [dateStr]: 1 },
          monthlyVisits: { [monthStr]: 1 },
          hourlyVisits: { [dateStr]: { [hourStr]: 1 } }
        });
      } else {
        const data = visitsDoc.data();
        transaction.update(visitsRef, {
          totalVisits: (data.totalVisits || 0) + 1,
          [`dailyVisits.${dateStr}`]: ((data.dailyVisits || {})[dateStr] || 0) + 1,
          [`monthlyVisits.${monthStr}`]: ((data.monthlyVisits || {})[monthStr] || 0) + 1,
          [`hourlyVisits.${dateStr}.${hourStr}`]: (((data.hourlyVisits || {})[dateStr] || {})[hourStr] || 0) + 1
        });
      }
    });
  } catch (error) {
    console.error('❌ Erreur tracking visites:', error);
  } finally {
    isTracking = false;
  }
};

// Tracking des installations par boutique
export const trackStoreInstallation = async (storeId, storeName, device) => {
  try {
    const shopRef = doc(db, 'shopInstall', storeId);
    
    // Obtenir la date et le mois actuels
    const today = new Date();
    const dailyKey = today.toISOString().split('T')[0];  // Format: "2024-03-19"
    const monthlyKey = dailyKey.substring(0, 7);  // Format: "2024-03"

    await runTransaction(db, async (transaction) => {
      const shopDoc = await transaction.get(shopRef);
      
      if (!shopDoc.exists()) {
        // Première installation pour cette boutique
        transaction.set(shopRef, {
          name: storeName,
          total: 0,
          byDevice: { [device]: 0 },
          dailyStats: {
            [dailyKey]: {
              total: 0,
              [device]: 0
            }
          },
          monthlyStats: {
            [monthlyKey]: {
              total: 0,
              [device]: 0
            }
          }
        });
      } else {
        const data = shopDoc.data();
        
        // Mise à jour des totaux
        const updates = {
          total: (data.total || 0) + 1,
          [`byDevice.${device}`]: ((data.byDevice || {})[device] || 0) + 1,
          
          // Mise à jour des stats journalières
          [`dailyStats.${dailyKey}.total`]: ((data.dailyStats || {})[dailyKey]?.total || 0) + 1,
          [`dailyStats.${dailyKey}.${device}`]: ((data.dailyStats || {})[dailyKey]?.[device] || 0) + 1,
          
          // Mise à jour des stats mensuelles
          [`monthlyStats.${monthlyKey}.total`]: ((data.monthlyStats || {})[monthlyKey]?.total || 0) + 1,
          [`monthlyStats.${monthlyKey}.${device}`]: ((data.monthlyStats || {})[monthlyKey]?.[device] || 0) + 1
        };

        transaction.update(shopRef, updates);
      }
    });
  } catch (error) {
    console.error('❌ Erreur tracking boutique:', error);
    await trackError({
      error,
      type: 'store_tracking',
      context: { storeId, storeName, device }
    });
  }
};

// Ajouter l'export de trackVisit
export const trackVisit = async (device) => {
  try {
    const visitsRef = doc(db, 'analytics', 'visits');
    const today = new Date().toISOString().split('T')[0];
    const month = today.substring(0, 7);
    const hour = new Date().getHours().toString().padStart(2, '0');

    await runTransaction(db, async (transaction) => {
      const doc = await transaction.get(visitsRef);
      const data = doc.data() || {};

      // Incrémenter total
      const totalVisits = (data.totalVisits || 0) + 1;

      // Incrémenter stats journalières
      const dailyVisits = data.dailyVisits || {};
      dailyVisits[today] = (dailyVisits[today] || 0) + 1;

      // Incrémenter stats mensuelles
      const monthlyVisits = data.monthlyVisits || {};
      monthlyVisits[month] = (monthlyVisits[month] || 0) + 1;

      // Incrémenter stats horaires
      const hourlyVisits = data.hourlyVisits || {};
      if (!hourlyVisits[today]) {
        hourlyVisits[today] = {};
      }
      hourlyVisits[today][hour] = (hourlyVisits[today][hour] || 0) + 1;

      transaction.set(visitsRef, {
        totalVisits,
        dailyVisits,
        monthlyVisits,
        hourlyVisits,
        lastUpdate: serverTimestamp()
      }, { merge: true });
    });
  } catch (error) {
    console.error('Erreur tracking visite:', error);
  }
}; 