import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../config/firebase';

export const trackError = async ({ error, type, context = {} }) => {
  try {
    // Générer un ID unique pour l'erreur
    const errorId = `error_${Date.now()}`;
    const errorRef = doc(db, 'analytics', 'errors');

    // Récupérer le contexte utilisateur
    const documentId = localStorage.getItem('documentId');
    const userAgent = navigator.userAgent;
    const path = window.location.pathname;

    // Créer l'objet erreur
    const errorData = {
      timestamp: serverTimestamp(),
      type: type,
      message: error.message,
      stack: error.stack,
      
      // Contexte technique
      device: /iPad|iPhone|iPod/.test(userAgent) ? 'iOS' : 'Android',
      userAgent: userAgent,
      path: path,
      
      // Contexte utilisateur
      userId: documentId || null,
      ...context
    };

    // Sauvegarder dans Firestore
    await setDoc(doc(db, 'analytics/errors/byError', errorId), errorData);

  } catch (err) {
    // Éviter la récursion en cas d'erreur dans le tracking
    console.error('Error tracking failed:', err);
  }
}; 