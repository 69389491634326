import React, { useState, useCallback } from 'react';
import './CardNews.css';

const CardNews = ({ nouveautes }) => {
  const [loadedImages, setLoadedImages] = useState({});

  const handleImageLoad = useCallback((id) => {
    setLoadedImages(prev => ({
      ...prev,
      [id]: true
    }));
  }, []);

  const handleCardClick = (url) => {
    if (url) {
      const fullUrl = url.startsWith('http') ? url : `https://${url}`;
      const anchor = document.createElement('a');
      anchor.setAttribute('href', fullUrl);
      anchor.setAttribute('target', '_blank');
      anchor.setAttribute('rel', 'noopener noreferrer');
      anchor.click();
    }
  };

  const isNewItem = (timestamp) => {
    if (!timestamp) return false;
    const now = new Date();
    const itemDate = new Date(timestamp);
    const diffTime = Math.abs(now - itemDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 7; // Considéré comme nouveau pendant 7 jours
  };

  return (
    <div className="news-cards-container">
      {nouveautes.length === 0 ? (
        <div className="news-empty-state">
          <p>Aucune nouveauté n'est disponible pour le moment.</p>
        </div>
      ) : (
        nouveautes.map((item, index) => (
          <article 
            key={item.id} 
            className="card-news"
            onClick={() => handleCardClick(item.urlRedirection)}
          >
            {isNewItem(item.timestamp) && (
              <div className="card-news__badge">Nouveauté</div>
            )}
            <div className="card-news__category-tag">
              {item.categorie}
            </div>

            <div className="card-news__image-container">
              <img
                src={item.imageUrl}
                alt={item.titre}
                className={`card-news__image ${loadedImages[item.id] ? 'loaded' : ''}`}
                loading="lazy"
                decoding="async"
                fetchPriority={index < 2 ? "high" : "low"}
                onLoad={() => handleImageLoad(item.id)}
                width="100%"
                height="auto"
              />
              {!loadedImages[item.id] && (
                <div className="card-news__image-placeholder">
                  {/* Suppression du rond de chargement */}
                </div>
              )}
            </div>
            
            <div className="card-news__content">
              <h3 className="card-news__title">{item.titre}</h3>
              <p className="card-news__description">{item.description}</p>
              <button 
                className="card-news__button"
                onClick={(e) => {
                  e.stopPropagation(); // Empêche le déclenchement du onClick de la card
                  handleCardClick(item.urlRedirection);
                }}
              >
                En savoir plus
              </button>
            </div>
          </article>
        ))
      )}
    </div>
  );
};

export default CardNews; 