import React, { useState, useRef, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase';
import ShopsMap from '../components/ShopsMap';
import ShopModal from '../components/ShopModal';
import Header from '../components/Header';
import './Shops.css';

const Shops = () => {
  const [selectedShop, setSelectedShop] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shops, setShops] = useState([]);
  const [loading, setLoading] = useState(true);
  const mapRef = useRef(null);

  useEffect(() => {
    // Créer un listener en temps réel
    const unsubscribe = onSnapshot(collection(db, 'shops'), (snapshot) => {
      const shopsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setShops(shopsData);
      
      // Mettre à jour le shop sélectionné si nécessaire
      if (selectedShop) {
        const updatedSelectedShop = shopsData.find(shop => shop.id === selectedShop.id);
        if (updatedSelectedShop) {
          // Préserver la distance lors de la mise à jour
          setSelectedShop({
            ...updatedSelectedShop,
            distance: selectedShop.distance // Garder la distance calculée
          });
        }
      }
      
      setLoading(false);
    }, (error) => {
      setLoading(false);
    });

    // Cleanup : arrêter l'écoute quand le composant est démonté
    return () => unsubscribe();
  }, [selectedShop]); // Dépendance à selectedShop pour la mise à jour

  const handleShopSelect = (shop) => {
    // Pas besoin d'alert pour le debug maintenant
    setSelectedShop(shop); // shop contient déjà la distance calculée
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedShop(null);
  };

  if (loading) return <div>Chargement...</div>;

  return (
    <div className="shops-page">
      <Header title="Boutiques" />
      <ShopsMap 
        shops={shops}
        selectedShop={selectedShop}
        onShopSelect={handleShopSelect}
        ref={mapRef}
      />

      {selectedShop && (
        <ShopModal
          shop={selectedShop}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default Shops; 