import React, { useState } from 'react';
import './FilterNews.css';

const FilterNews = ({ onFilterChange }) => {
  const [showModal, setShowModal] = useState(false);
  const [tempCategory, setTempCategory] = useState('all');
  const [activeCategory, setActiveCategory] = useState('all');

  // Ces IDs doivent correspondre aux clés du categoryMapping dans News.js
  const categories = [
    { id: 'all', label: 'Toutes' },
    { id: 'eliquide', label: 'E-liquide' },
    { id: 'ecigarette', label: 'E-cigarette' },
    { id: 'cbd', label: 'CBD' },
    { id: 'accessoire', label: 'Accessoire' }
  ];

  const handleCategoryClick = (categoryId) => {
    setTempCategory(categoryId);
  };

  const handleApplyFilters = () => {
    setActiveCategory(tempCategory);
    onFilterChange(tempCategory);
    setShowModal(false);
  };

  const handleModalOpen = () => {
    setTempCategory(activeCategory);
    setShowModal(true);
  };

  return (
    <>
      <div className="filter-button-container">
        <button className="filter-button" onClick={handleModalOpen}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path fillRule="evenodd" d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z" clipRule="evenodd" />
          </svg>
        </button>
      </div>

      {showModal && (
        <div className="filter-modal" onClick={() => setShowModal(false)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h3>Filtrer les actualités</h3>

            <div className="filter-section">
              <h4>Catégorie</h4>
              <div className="buttons-grid">
                {categories.map(category => (
                  <button
                    key={category.id}
                    className={`filter-option ${tempCategory === category.id ? 'active' : ''}`}
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    {category.label}
                  </button>
                ))}
              </div>
            </div>

            <button className="close-button" onClick={handleApplyFilters}>
              Appliquer les filtres
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterNews; 