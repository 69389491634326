import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import News from './pages/News';
import Deals from './pages/Deals';
import Shops from './pages/Shops';
import Notifications from './pages/Notifications';
import Feedback from './pages/Feedback';
import BottomNavigation from './components/BottomNavigation';
import Header from './components/Header';

const AppContent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Vérifier s'il y a une redirection en attente
    const redirectRoute = sessionStorage.getItem('notification_redirect');
    if (redirectRoute) {
      // Effacer la redirection stockée
      sessionStorage.removeItem('notification_redirect');
      // Effectuer la redirection
      navigate(redirectRoute);
    }
  }, [navigate]);

  // Mapping des titres en fonction des routes
  const pageTitles = {
    '/': 'Nouveautés',
    '/feedback': 'Suggestions',
    '/news': 'Nouveautés',
    '/deals': 'Bons Plans',
    '/shops': 'Boutiques',
    '/notifications': 'Notifications',
    // Ajoute d'autres routes si nécessaire
  };

  // Déterminer le titre en fonction de la route actuelle
  const title = pageTitles[location.pathname] || 'Page';

  return (
    <>
      <Header title={title} />
      <main>
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<PageContent><News /></PageContent>} />
            <Route path="/news" element={<PageContent><News /></PageContent>} />
            <Route path="/deals" element={<PageContent><Deals /></PageContent>} />
            <Route path="/shops" element={<PageContent><Shops /></PageContent>} />
            <Route path="/notifications" element={<PageContent><Notifications /></PageContent>} />
            <Route path="/feedback" element={<PageContent><Feedback /></PageContent>} />
          </Routes>
        </AnimatePresence>
      </main>
      <BottomNavigation />
    </>
  );
};

// Composant pour wrapper le contenu avec l'animation
const PageContent = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.3, ease: "easeInOut" }}
  >
    {children}
  </motion.div>
);

export default AppContent; 