import { doc, serverTimestamp, setDoc, updateDoc, getDoc, runTransaction } from 'firebase/firestore';
import { db } from '../config/firebase';
import { trackStoreInstallation } from './analytics';
import { trackError } from './errorTracking';

// Création du document initial avec l'ID permanent
export const createInitialDocument = async (device, oneSignalId = null, isSubscribed = false) => {
  // Si c'est un ancien utilisateur avec ID OneSignal
  if (oneSignalId) {
    // Vérifier si on a déjà créé un document pour cet ancien utilisateur
    if (localStorage.getItem('initialDocumentCreated')) {
      return localStorage.getItem('documentId');
    }
    
    try {
      const documentId = `install_${Date.now()}`;
      const userRef = doc(db, 'users', documentId);

      await setDoc(userRef, {
        device,
        firstInstallDate: serverTimestamp(),
        lastVisitDate: serverTimestamp(),
        totalVisits: 1,
        installationTracked: true,
        notificationsEnabled: isSubscribed,
        oneSignalId: oneSignalId,
        storeId: null,
        storeName: null
      });

      localStorage.setItem('initialDocumentCreated', 'true');
      localStorage.setItem('documentId', documentId);

      return documentId;
    } catch (error) {
      console.error('❌ Erreur création document:', error);
      await trackError({
        error,
        type: 'document_creation',
        context: { device, oneSignalId, isSubscribed }
      });
      return null;
    }
  }

  // Pour les nouveaux utilisateurs, garder la vérification
  if (localStorage.getItem('initialDocumentCreated')) {
    return localStorage.getItem('documentId');
  }

  try {
    const documentId = `install_${Date.now()}`;
    const userRef = doc(db, 'users', documentId);

    await setDoc(userRef, {
      device,
      firstInstallDate: serverTimestamp(),
      lastVisitDate: serverTimestamp(),
      totalVisits: 1,
      installationTracked: true,
      notificationsEnabled: isSubscribed,
      oneSignalId: oneSignalId,
      storeId: null,
      storeName: null
    });

    localStorage.setItem('initialDocumentCreated', 'true');
    localStorage.setItem('documentId', documentId);

    return documentId;
  } catch (error) {
    console.error('❌ Erreur création document:', error);
    await trackError({
      error,
      type: 'document_creation',
      context: { device, oneSignalId, isSubscribed }
    });
    return null;
  }
};

// Mise à jour du statut des notifications
export const updateNotificationStatus = async (documentId, oneSignalId, isSubscribed) => {
  try {
    // 1. Mise à jour du document utilisateur
    const userRef = doc(db, 'users', documentId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) return;

    await updateDoc(userRef, {
      oneSignalId: isSubscribed ? oneSignalId : null,
      notificationsEnabled: isSubscribed
    });

    // 2. Mise à jour de pushStats/devices pour refléter OneSignal
    if (oneSignalId) {
      const pushStatsRef = doc(db, 'analytics', 'pushStats');
      await runTransaction(db, async (transaction) => {
        const pushStatsDoc = await transaction.get(pushStatsRef);
        
        // Obtenir le jour et le mois actuels
        const today = new Date();
        const monthKey = today.toISOString().split('T')[0].substring(0, 7); // "2024-03"
        const dailyKey = today.toISOString().split('T')[0]; // "2024-03-19"
        
        if (!pushStatsDoc.exists()) {
          transaction.set(pushStatsRef, {
            devices: {
              [oneSignalId]: isSubscribed
            },
            totalSubscribed: isSubscribed ? 1 : 0,
            monthlyStats: {
              [monthKey]: {
                total: isSubscribed ? 1 : 0
              }
            },
            dailyStats: {
              [dailyKey]: {
                total: isSubscribed ? 1 : 0
              }
            }
          });
        } else {
          const data = pushStatsDoc.data();
          const oldStatus = data.devices?.[oneSignalId] || false;
          
          // Calculer le changement pour totalSubscribed
          const subscribedDelta = isSubscribed && !oldStatus ? 1 : 
                                 !isSubscribed && oldStatus ? -1 : 0;
          
          transaction.update(pushStatsRef, {
            [`devices.${oneSignalId}`]: isSubscribed,
            totalSubscribed: (data.totalSubscribed || 0) + subscribedDelta,
            [`monthlyStats.${monthKey}.total`]: ((data.monthlyStats || {})[monthKey]?.total || 0) + subscribedDelta,
            [`dailyStats.${dailyKey}.total`]: ((data.dailyStats || {})[dailyKey]?.total || 0) + subscribedDelta
          });
        }
      });
    }
  } catch (error) {
    console.error('❌ Erreur mise à jour notifications:', error);
    await trackError({
      error,
      type: 'notification_update',
      context: { documentId, oneSignalId, isSubscribed }
    });
  }
};

// Mise à jour de la sélection boutique
export const updateStoreSelection = async (documentId, storeId, storeName) => {
  try {
    const userRef = doc(db, 'users', documentId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) return;

    await updateDoc(userRef, {
      storeId,
      storeName,
      lastVisitDate: serverTimestamp()
    });

    // Ajouter un flag pour ne plus montrer le modal
    localStorage.setItem('storeSelected', 'true');

    // Utiliser le device stocké dans le document
    const device = userDoc.data().device;
    await trackStoreInstallation(storeId, storeName, device);

  } catch (error) {
    console.error('❌ Erreur mise à jour boutique:', error);
  }
};

// Mise à jour des stats de visite
export const updateVisitStats = async (documentId) => {
  try {
    const userRef = doc(db, 'users', documentId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) return;

    const data = userDoc.data();
    await updateDoc(userRef, {
      lastVisitDate: serverTimestamp(),
      totalVisits: (data.totalVisits || 0) + 1
    });
  } catch (error) {
    console.error('❌ Erreur mise à jour visites:', error);
  }
}; 