import { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import CardDeals from '../components/CardDeals';
import './Deals.css';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import PageTransition from '../components/PageTransition';

const Deals = () => {
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchDeals = async () => {
    try {
      const q = query(
        collection(db, 'deals'),
        orderBy('timestamp', 'desc')
      );
      
      const querySnapshot = await getDocs(q);
      const dealsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setDeals(dealsData);
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la récupération des deals:", error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeals();
  }, []);

  const handleNotificationClick = () => {
    navigate('/notifications');
  };

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Une erreur est survenue...</div>;

  return (
    <div className="deals-page">
      <CardDeals deals={deals} />
    </div>
  );
};

export default Deals; 