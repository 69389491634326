import React, { useState } from 'react';
import './ShopModal.css';
import { isIOS, isAndroid, isMobile } from 'react-device-detect';

const daysOfWeek = [
  { key: 'sunday', label: 'Dimanche' },
  { key: 'monday', label: 'Lundi' },
  { key: 'tuesday', label: 'Mardi' },
  { key: 'wednesday', label: 'Mercredi' },
  { key: 'thursday', label: 'Jeudi' },
  { key: 'friday', label: 'Vendredi' },
  { key: 'saturday', label: 'Samedi' }
];

const formatDayHours = (dayHours, date, holidays, specialHours) => {
  // Si c'est un jour férié
  if (holidays && holidays[date]) {
    return "Férié";
  }

  // Si c'est un jour avec des horaires spéciaux
  if (specialHours && specialHours[date]) {
    const specialDay = specialHours[date];
    if (!specialDay) return "Fermé";
    
    let schedule = [];
    if (specialDay.morning?.open && specialDay.morning?.close) {
      schedule.push(`${specialDay.morning.open}-${specialDay.morning.close}`);
    }
    if (specialDay.afternoon?.open && specialDay.afternoon?.close) {
      schedule.push(`${specialDay.afternoon.open}-${specialDay.afternoon.close}`);
    }
    // Retourner un format plus compact
    return schedule.length > 0 ? `(Spécial) ${schedule.join(', ')}` : "Fermé";
  }

  // Horaires normaux
  if (!dayHours) return "Fermé";
  
  let schedule = [];
  if (dayHours.morning?.open && dayHours.morning?.close) {
    schedule.push(`${dayHours.morning.open}-${dayHours.morning.close}`);
  }
  if (dayHours.afternoon?.open && dayHours.afternoon?.close) {
    schedule.push(`${dayHours.afternoon.open}-${dayHours.afternoon.close}`);
  }
  
  return schedule.length > 0 ? schedule.join(', ') : "Fermé";
};

const isShopOpen = (hours, holidays, specialHours) => {
  if (!hours) return false;

  const now = new Date();
  const today = now.toISOString().split('T')[0]; // Format YYYY-MM-DD

  // Vérifier si c'est un jour férié
  if (holidays && holidays[today]) {
    return false;
  }

  // Vérifier s'il y a des horaires spéciaux pour aujourd'hui
  if (specialHours && specialHours[today]) {
    const specialToday = specialHours[today];
    return checkTimeInRange(now, specialToday);
  }

  // Sinon, utiliser les horaires normaux
  const currentDay = daysOfWeek[now.getDay()].key;
  const todayHours = hours[currentDay];
  
  return checkTimeInRange(now, todayHours);
};

// Fonction utilitaire pour vérifier si l'heure actuelle est dans la plage
const checkTimeInRange = (now, hours) => {
  if (!hours) return false;

  const currentHour = now.getHours();
  const currentMinutes = now.getMinutes();
  const currentTimeInMinutes = currentHour * 60 + currentMinutes;

  if (hours.morning?.open && hours.morning?.close) {
    const [morningOpenHour, morningOpenMinutes] = hours.morning.open.split(':').map(Number);
    const [morningCloseHour, morningCloseMinutes] = hours.morning.close.split(':').map(Number);
    
    const morningOpenTime = morningOpenHour * 60 + morningOpenMinutes;
    const morningCloseTime = morningCloseHour * 60 + morningCloseMinutes;
    
    if (currentTimeInMinutes >= morningOpenTime && currentTimeInMinutes <= morningCloseTime) {
      return true;
    }
  }

  if (hours.afternoon?.open && hours.afternoon?.close) {
    const [afterOpenHour, afterOpenMinutes] = hours.afternoon.open.split(':').map(Number);
    const [afterCloseHour, afterCloseMinutes] = hours.afternoon.close.split(':').map(Number);
    
    const afterOpenTime = afterOpenHour * 60 + afterOpenMinutes;
    const afterCloseTime = afterCloseHour * 60 + afterCloseMinutes;
    
    if (currentTimeInMinutes >= afterOpenTime && currentTimeInMinutes <= afterCloseTime) {
      return true;
    }
  }

  return false;
};

const ShopModal = ({ shop, isOpen, onClose }) => {
  console.log('Modal shop data:', shop); // Pour déboguer
  
  const [isHoursExpanded, setIsHoursExpanded] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [translateY, setTranslateY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  
  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientY);
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (!touchStart) return;
    e.preventDefault(); // Empêcher le scroll de la page

    const currentTouch = e.touches[0].clientY;
    const diff = currentTouch - touchStart;
    
    // Suivre le doigt avec une légère résistance
    const resistance = diff > 0 ? 0.8 : 0.4;
    const movement = diff * resistance;
    
    requestAnimationFrame(() => {
      setTranslateY(movement);
    });
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    
    if (translateY > 150) { // Seuil pour fermer
      onClose();
    } else {
      // Animation de retour à la position initiale
      setTranslateY(0);
    }
    setTouchStart(null);
  };

  const handleAddressClick = () => {
    const { coordinates, address, titre } = shop;
    const encodedAddress = encodeURIComponent(`${titre}, ${address}`);
    const directionsUrl = `google.navigation:q=${encodedAddress}`;
    const fallbackUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;

    if (isMobile) {
      if (isIOS) {
        window.location = `maps://?address=${encodedAddress}&dirflg=d`;
      } else if (isAndroid) {
        window.location = directionsUrl;
      }
    } else {
      window.open(fallbackUrl, '_blank');
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`shop-modal-overlay ${isOpen ? 'show' : ''}`} onClick={onClose}>
      <div className="shop-modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-drag-indicator"></div>
        <div className="shop-modal-info">
          <h2>{shop.titre}</h2>
          
          {shop.phoneNumber && (
            <div className="shop-modal-detail clickable" onClick={() => window.location.href = `tel:${shop.phoneNumber}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="modal-icon">
                <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
              </svg>
              <span>Appeler : {shop.phoneNumber}</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="arrow-icon">
                <path d="M9 5l7 7-7 7" />
              </svg>
            </div>
          )}
          
          {shop.address && (
            <div 
              className="shop-modal-detail clickable" 
              onClick={handleAddressClick}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="modal-icon">
                <path fillRule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
              </svg>
              <span>Voir l'itinéraire</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="arrow-icon">
                <path d="M9 5l7 7-7 7" />
              </svg>
            </div>
          )}
          
          {shop.hours && (
            <div className="shop-modal-hours">
              <div 
                className={`shop-modal-detail ${isShopOpen(shop.hours, shop.holidays, shop.specialHours) ? 'open' : 'closed'}`}
                onClick={() => setIsHoursExpanded(!isHoursExpanded)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="modal-icon">
                  <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z" clipRule="evenodd" />
                </svg>
                <div className="hours-info">
                  <span className="status-indicator">
                    {isShopOpen(shop.hours, shop.holidays, shop.specialHours) ? 'Ouvert' : 'Fermé'}
                  </span>
                </div>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 24 24" 
                  fill="currentColor" 
                  className={`chevron-icon ${isHoursExpanded ? 'expanded' : ''}`}
                >
                  <path d="M12 15.75l-6.375-6.375 1.06-1.06L12 13.628l5.315-5.314 1.06 1.06z"/>
                </svg>
              </div>
              
              <div className={`weekly-hours ${isHoursExpanded ? 'expanded' : ''}`}>
                {daysOfWeek.map((day) => {
                  // Obtenir la date du prochain jour correspondant
                  const now = new Date();
                  const currentDay = now.getDay();
                  const targetDay = daysOfWeek.findIndex(d => d.key === day.key);
                  const daysUntilTarget = (targetDay + 7 - currentDay) % 7;
                  
                  const date = new Date(now);
                  date.setDate(date.getDate() + daysUntilTarget);
                  const dateString = date.toISOString().split('T')[0];

                  return (
                    <div key={day.key} className={`day-hours ${
                      shop.holidays?.[dateString] ? 'holiday' : 
                      shop.specialHours?.[dateString] ? 'special' : ''
                    }`}>
                      <span className="day-label">{day.label}</span>
                      <span className="hours-label">
                        {formatDayHours(
                          shop.hours[day.key],
                          dateString,
                          shop.holidays,
                          shop.specialHours
                        )}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShopModal; 