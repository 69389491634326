import React, { useState } from 'react';
import ReinstallModal from './ReinstallModal';
import { trackError } from '../services/errorTracking';
import { handleNotificationReactivation } from '../services/notifications';
import './NotificationButton.css';

const NotificationButton = ({ permission }) => {
  const [showReinstallModal, setShowReinstallModal] = useState(false);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const handleClick = async () => {
    try {
      if (permission === 'default') {
        await window.OneSignal.registerForPushNotifications();
      } else if (permission === 'denied') {
        if (isIOS) {
          setShowReinstallModal(true);
        } else {
          const result = await handleNotificationReactivation();
        }
      }
    } catch (error) {
      console.error('Erreur activation notifications:', error);
    }
  };

  return (
    <>
      <button className="notification-button" onClick={handleClick}>
        Activer les notifications
      </button>
      
      {showReinstallModal && (
        <ReinstallModal onClose={() => setShowReinstallModal(false)} />
      )}
    </>
  );
};

export default NotificationButton; 