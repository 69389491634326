import React, { useState, useEffect } from 'react';
import StoreSelectionModal from './StoreSelectionModal';
import { trackStoreInstallation, trackVisit } from '../services/analytics';
import { updateStoreSelection } from '../services/userDocument';

const StoreSelectionModalWrapper = () => {
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const handleShowModal = (event) => {
      setUserData(event.detail);
      setShowModal(true);
    };

    window.addEventListener('showStoreModal', handleShowModal);
    return () => window.removeEventListener('showStoreModal', handleShowModal);
  }, []);

  const handleStoreSelect = async (store) => {
    if (userData) {
      const documentId = localStorage.getItem('documentId');
      if (documentId) {
        await updateStoreSelection(documentId, store.id, store.name);
        await trackVisit(userData.device);
        setShowModal(false);
      }
    }
  };

  return showModal ? <StoreSelectionModal onStoreSelect={handleStoreSelect} /> : null;
};

export default StoreSelectionModalWrapper; 