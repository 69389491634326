import { trackError } from './errorTracking';
import { updateNotificationStatus } from './userDocument';

// Vérification du statut des notifications
export const checkNotificationStatus = async () => {
  try {
    const [permission, isSubscribed] = await Promise.all([
      window.OneSignal.getNotificationPermission(),
      window.OneSignal.getSubscription()
    ]);
    
    return {
      permission,
      isSubscribed,
      isEnabled: permission === 'granted' && isSubscribed
    };
  } catch (error) {
    console.error('❌ Erreur vérification notifications:', error);
    await trackError({
      error,
      type: 'notification_check',
      context: { 
        permission: await window.OneSignal.getNotificationPermission(),
        isSubscribed: await window.OneSignal.getSubscription()
      }
    });
    return {
      permission: 'denied',
      isSubscribed: false,
      isEnabled: false
    };
  }
};

// Gestion des changements de notifications
export const handleNotificationChange = async (isEnabled) => {
  try {
    if (isEnabled) {
      const permission = await window.OneSignal.getNotificationPermission();
      if (permission === 'granted') {
        await window.OneSignal.setSubscription(true);
      } else {
        await window.OneSignal.showSlidedownPrompt();
      }
    } else {
      await window.OneSignal.setSubscription(false);
    }
    
    return await checkNotificationStatus();
  } catch (error) {
    console.error('❌ Erreur changement notifications:', error);
    return null;
  }
};

// Gestion de la réactivation des notifications
export const handleNotificationReactivation = async () => {
  const device = /iPad|iPhone|iPod/.test(navigator.userAgent) ? 'iOS' : 'Android';
  
  try {
    // 1. Réinitialiser l'état
    await window.OneSignal.setSubscription(false);
    
    // 2. Petit délai pour être sûr
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    // 3. Afficher le slidedown OneSignal
    await window.OneSignal.showSlidedownPrompt();
    
    // 4. Pour Android, montrer aussi le prompt natif
    if (device === 'Android') {
      await window.OneSignal.showNativePrompt();
    }
    
    // 5. Vérifier si tout est accepté
    const [permission, isSubscribed] = await Promise.all([
      window.OneSignal.getNotificationPermission(),
      window.OneSignal.getSubscription()
    ]);
    
    if (permission === 'granted' && isSubscribed) {
      const oneSignalId = await window.OneSignal.getUserId();
      const documentId = localStorage.getItem('documentId');
      
      if (documentId && oneSignalId) {
        await updateNotificationStatus(documentId, oneSignalId, true);
      }
      
      return {
        success: true,
        oneSignalId,
        isSubscribed: true
      };
    }
    
    return {
      success: false,
      isSubscribed: false
    };
  } catch (error) {
    console.error('❌ Erreur réactivation notifications:', error);
    await trackError({
      error,
      type: 'notification_reactivation',
      context: { device }
    });
    return {
      success: false,
      error
    };
  }
}; 