import React, { useEffect, useState } from 'react';
import './AndroidInstallPrompt.css';

const AndroidInstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isAndroidChrome, setIsAndroidChrome] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false);
  const [showThanks, setShowThanks] = useState(false);

  useEffect(() => {
    // Détection Android Chrome
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isChrome = /Chrome/i.test(navigator.userAgent);
    setIsAndroidChrome(isAndroid && isChrome);
    
    // Vérification si déjà installé
    setIsStandalone(window.matchMedia('(display-mode: standalone)').matches);

    // Capture de l'événement d'installation
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {
    if (!deferredPrompt) return;

    try {
      const result = await deferredPrompt.prompt();
      console.log('Résultat installation PWA:', result);
      
      setDeferredPrompt(null);
      
      if (result.outcome === 'accepted') {
        setShowThanks(true);
        // Donner du temps pour l'installation
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    } catch (error) {
      console.error('Erreur installation PWA:', error);
    }
  };

  // N'afficher que sur Android Chrome et si pas déjà installé
  if (!isAndroidChrome || isStandalone) {
    return null;
  }

  return (
    <div className="android-install-page">
      {!showThanks ? (
        <div className="android-install-content">
          <img src="/image/logo192.png" alt="Logo" className="android-install-logo" />
          <h2>Bienvenue chez Hype Vap !</h2>
          <p>Installez notre application pour être informé en temps réel des nouveautés et bons plans exclusifs de votre boutique préférée.</p>
          <button 
            onClick={handleInstallClick} 
            className="install-button"
            disabled={!deferredPrompt}
          >
            Installer l'application
          </button>
        </div>
      ) : (
        <div className="installation-success">
          <img src="/image/logo192.png" alt="Logo" className="android-install-logo" />
          <h2>Installation réussie !</h2>
          <p>Merci d'avoir installé l'application Hype Vap !</p>
        </div>
      )}
    </div>
  );
};

export default AndroidInstallPrompt; 