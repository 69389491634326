import React, { useState } from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: fadeIn 0.3s ease;

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 20px;
  width: 90%;
  max-width: 400px;
  animation: slideUp 0.3s ease;
  overflow: hidden;

  @keyframes slideUp {
    from { transform: translateY(50px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin: 0 0 8px 0;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0 0 20px 0;
  text-align: center;
`;

const StoresList = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  margin: 0 -20px;
  padding: 0 20px;
`;

const StoreCard = styled.div`
  padding: 15px;
  margin: 8px 0;
  border-radius: 12px;
  border: 2px solid ${props => props.selected ? '#3DB600' : 'transparent'};
  background: ${props => props.selected ? '#f0f9f0' : '#f8f8f8'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;
  cursor: pointer;

  &:active {
    transform: scale(0.98);
  }
`;

const StoreName = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

const CheckIcon = styled.span`
  color: #3DB600;
  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity 0.2s ease;
`;

const Button = styled.button`
  width: 100%;
  padding: 16px;
  background: #3DB600;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  opacity: ${props => props.disabled ? 0.5 : 1};
  transition: all 0.2s ease;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};

  &:active {
    transform: ${props => props.disabled ? 'none' : 'scale(0.98)'};
  }
`;

const stores = [
  { id: 'L\'Union', name: 'L\'Union' },
  { id: 'ramonville', name: 'Ramonville' },
  { id: 'seysses', name: 'Seysses' },
  { id: 'cornebarrieu', name: 'Cornebarrieu' },
  { id: 'seilh', name: 'Seilh' },
  { id: 'lespinasse', name: 'Lespinasse' },
  { id: 'grenade', name: 'Grenade'},
  { id: 'toulouse', name: 'Toulouse'},
  { id: 'share', name: 'Partage' },
];

const StoreSelectionModal = ({ onStoreSelect }) => {
  const [selectedStore, setSelectedStore] = useState(null);

  const handleStoreClick = (store) => {
    setSelectedStore(store);
  };

  const handleSubmit = () => {
    if (selectedStore) {
      onStoreSelect(selectedStore);
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <Title>Choisissez votre boutique</Title>
        <Subtitle>Cette sélection est définitive</Subtitle>
        
        <StoresList>
          {stores.map(store => (
            <StoreCard
              key={store.id}
              selected={selectedStore?.id === store.id}
              onClick={() => handleStoreClick(store)}
            >
              <StoreName>{store.name}</StoreName>
              <CheckIcon visible={selectedStore?.id === store.id}>✓</CheckIcon>
            </StoreCard>
          ))}
        </StoresList>

        <Button 
          onClick={handleSubmit} 
          disabled={!selectedStore}
        >
          Confirmer
        </Button>
      </ModalContent>
    </ModalOverlay>
  );
};

export default StoreSelectionModal; 