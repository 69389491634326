import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './CardDeals.css';

const CardDeals = ({ deals }) => {
  const [loadedImages, setLoadedImages] = useState({});
  const [selectedDeal, setSelectedDeal] = useState(null);
  const navigate = useNavigate();

  const handleImageLoad = useCallback((id) => {
    setLoadedImages(prev => ({
      ...prev,
      [id]: true
    }));
  }, []);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    
    try {
      const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();
      
      const moisFr = [
        'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
        'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
      ];
      
      return `${day} ${moisFr[month]} ${year}`;
    } catch (error) {
      console.error('Erreur de formatage de date:', error);
      return 'Date non disponible';
    }
  };

  const handleCardClick = (url) => {
    if (url) {
      const fullUrl = url.startsWith('http') ? url : `https://${url}`;
      const anchor = document.createElement('a');
      anchor.setAttribute('href', fullUrl);
      anchor.setAttribute('target', '_blank');
      anchor.setAttribute('rel', 'noopener noreferrer');
      anchor.click();
    }
  };

  const redirectToStores = () => {
    navigate('/shops');
  };

  const handleShowDetails = (deal) => {
    switch (deal.type) {
      case 'store':
        if (deal.dateDebut || deal.dateFin || deal.conditions) {
          setSelectedDeal(deal);
        } else {
          redirectToStores();
        }
        break;

      case 'both':
        setSelectedDeal(deal);
        break;

      case 'online':
        if (!deal.dateDebut && !deal.dateFin && !deal.conditions) {
          handleCardClick(deal.urlRedirection);
        } else {
          setSelectedDeal(deal);
        }
        break;

      case 'announcement':
        if (deal.urlRedirection) {
          handleCardClick(deal.urlRedirection);
        }
        break;

      default:
        console.warn('Type de deal non reconnu:', deal.type);
    }
  };

  const handleCloseModal = () => {
    setSelectedDeal(null);
  };

  return (
    <>
      <div className="deals-cards-container">
        {deals.length === 0 ? (
          <div className="deals-empty-state">
            <p>Aucun bon plan n'est disponible pour le moment.</p>
          </div>
        ) : (
          deals.map((item, index) => (
            <article 
              key={item.id} 
              className="card-deals"
            >
              <div className="card-deals__image-container">
                <img
                  src={item.imageUrl}
                  alt={item.titre}
                  className={`card-deals__image ${loadedImages[item.id] ? 'loaded' : ''}`}
                  loading="lazy"
                  decoding="async"
                  fetchPriority={index < 2 ? "high" : "low"}
                  onLoad={() => handleImageLoad(item.id)}
                  width="100%"
                  height="auto"
                />
                {!loadedImages[item.id] && (
                  <div className="card-deals__image-placeholder">
                  </div>
                )}
              </div>
              
              <div className="card-deals__content">
                {!(item.type === 'announcement' && !item.urlRedirection) && (
                  <button 
                    className="card-deals__button"
                    onClick={() => handleShowDetails(item)}
                  >
                    En savoir plus
                  </button>
                )}
              </div>
            </article>
          ))
        )}
      </div>

      {selectedDeal && (
        <div 
          className={`card-deals__modal-overlay ${selectedDeal ? 'show' : ''}`}
          onClick={handleCloseModal}
        >
          <div 
            className="card-deals__modal"
            onClick={e => e.stopPropagation()}
          >
            <button 
              className="card-deals__modal-close"
              onClick={handleCloseModal}
              aria-label="Fermer"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24" 
                fill="currentColor"
                width="24"
                height="24"
              >
                <path d="M6.225 4.811a1 1 0 00-1.414 1.414L10.586 12 4.81 17.775a1 1 0 101.414 1.414L12 13.414l5.775 5.775a1 1 0 001.414-1.414L13.414 12l5.775-5.775a1 1 0 00-1.414-1.414L12 10.586 6.225 4.81z"/>
              </svg>
            </button>
            <div className={`card-deals__modal-content ${
              selectedDeal.type === 'both' && !selectedDeal.conditions && !selectedDeal.dateDebut ? 
              'both-choices' : ''
            }`}>
              {selectedDeal.type === 'both' && !selectedDeal.conditions && !selectedDeal.dateDebut ? (
                <div className="card-deals__modal-content">
                  <div className="card-deals__info-message">
                    <h3>Cette offre est disponible :</h3>
                    <p>En ligne sur le site web ou directement en magasin près de chez vous.</p>
                  </div>
                  <div className="card-deals__actions dual-buttons">
                    <button 
                      className="card-deals__redirect online"
                      onClick={() => handleCardClick(selectedDeal.urlRedirection)}
                    >
                      Voir en ligne
                    </button>
                    <button 
                      className="card-deals__redirect store"
                      onClick={redirectToStores}
                    >
                      Voir en boutique
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  {selectedDeal.dateDebut && selectedDeal.dateFin && (
                    <div className="card-deals__validity">
                      <span>Du {formatDate(selectedDeal.dateDebut)} au {formatDate(selectedDeal.dateFin)}</span>
                    </div>
                  )}
                  {selectedDeal.conditions && (
                    <div className="card-deals__conditions">
                      <h3>Conditions</h3>
                      <p>{selectedDeal.conditions}</p>
                    </div>
                  )}
                  <div className={`card-deals__actions ${selectedDeal.type === 'both' ? 'dual-buttons' : ''}`}>
                    {selectedDeal.type === 'both' ? (
                      <>
                        <button 
                          className="card-deals__redirect online"
                          onClick={() => handleCardClick(selectedDeal.urlRedirection)}
                        >
                          Voir en ligne
                        </button>
                        <button 
                          className="card-deals__redirect store"
                          onClick={redirectToStores}
                        >
                          Voir en boutique
                        </button>
                      </>
                    ) : selectedDeal.type === 'store' ? (
                      <button 
                        className="card-deals__redirect store"
                        onClick={redirectToStores}
                      >
                        Voir en boutique
                      </button>
                    ) : (
                      <button 
                        className="card-deals__redirect online"
                        onClick={() => handleCardClick(selectedDeal.urlRedirection)}
                      >
                        Voir en ligne
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CardDeals; 