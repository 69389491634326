import React, { useState, useEffect } from 'react';
import './SwitchToggle.css';
import { updateNotificationStatus } from '../services/userDocument';
import { trackError } from '../services/errorTracking';

const SwitchToggle = () => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkNotificationStatus = async () => {
    setIsLoading(true);
    try {
      const isSubscribed = await window.OneSignal.getSubscription();
      setIsEnabled(isSubscribed);
    } catch (error) {
      console.error('Erreur check status:', error);
      await trackError({
        error,
        type: 'subscription_check',
        context: { isEnabled }
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggle = async () => {
    try {
      setIsLoading(true);
      
      // Simple toggle de subscription
      await window.OneSignal.setSubscription(!isEnabled);
      
      // Mise à jour dans la base de données
      const documentId = localStorage.getItem('documentId');
      if (documentId) {
        const oneSignalId = await window.OneSignal.getUserId();
        await updateNotificationStatus(documentId, oneSignalId, !isEnabled);
      }
      
      await checkNotificationStatus();
    } catch (error) {
      console.error('Erreur toggle:', error);
      await trackError({
        error,
        type: 'subscription_toggle',
        context: { isEnabled }
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkNotificationStatus();
    
    // Écouter les changements de subscription
    window.OneSignal.on('subscriptionChange', checkNotificationStatus);
    return () => window.OneSignal.off('subscriptionChange', checkNotificationStatus);
  }, []);

  return (
    <div className="switch-container">
      <label className="switch">
        <input
          type="checkbox"
          checked={isEnabled}
          onChange={handleToggle}
          disabled={isLoading}
        />
        <span className={`slider round ${isLoading ? 'loading' : ''}`}></span>
      </label>
      <span className="switch-label">
        {isLoading ? (
          <span className="switch-loading">Chargement...</span>
        ) : (
          `Notifications ${isEnabled ? 'activées' : 'désactivées'}`
        )}
      </span>
    </div>
  );
};

export default SwitchToggle; 