import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import './styles/variables.css';
import './styles/global.css';
import AppContent from './AppContent';
import { 
  trackAppVisit, 
  trackStoreInstallation
} from './services/analytics';
import AndroidInstallPrompt from './components/AndroidInstallPrompt';
import IOSInstallPrompt from './components/IOSInstallPrompt';
import DesktopInstallPrompt from './components/DesktopInstallPrompt';
import StoreSelectionModalWrapper from './components/StoreSelectionModalWrapper';
import {
  createInitialDocument,
  updateNotificationStatus,
  updateStoreSelection,
  updateVisitStats
} from './services/userDocument';
import {
  checkNotificationStatus,
  handleNotificationChange
} from './services/notifications';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './config/firebase';

const AppContainer = styled.div`
  min-height: 100vh;
  background: var(--background-gradient);
`;

const App = () => {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  
  // Détection iOS plus stricte pour inclure tous les appareils iOS
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) || 
                (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) ||
                /CriOS|FxiOS|EdgiOS/.test(navigator.userAgent); // Détecte aussi Chrome, Firefox et Edge sur iOS
  
  const isAndroid = /Android/.test(navigator.userAgent);
  
  // Tablette uniquement si ce n'est pas iOS et pas Android
  const isTablet = !isIOS && !isAndroid && (
    window.innerWidth >= 768 && window.innerHeight >= 768
  );

  useEffect(() => {
    const initializeApp = async () => {
      if (isStandalone) {
        const documentId = localStorage.getItem('documentId');
        if (documentId) {
          await updateVisitStats(documentId);
          await trackAppVisit(isStandalone);
        }
      }
    };

    initializeApp();
  }, [isStandalone]);

  // Exposer uniquement les fonctions nécessaires
  window.createInitialDocument = createInitialDocument;
  window.updateNotificationStatus = updateNotificationStatus;
  window.updateStoreSelection = updateStoreSelection;
  window.db = db;
  window.doc = doc;
  window.getDoc = getDoc;

  // Si c'est déjà installé, afficher l'application
  if (isStandalone) {
    return (
      <Router>
        <AppContainer>
          <AppContent />
          <StoreSelectionModalWrapper />
        </AppContainer>
      </Router>
    );
  }

  // Si c'est une tablette (non iOS/Android), montrer le QR code
  if (isTablet) {
    return <DesktopInstallPrompt />;
  }

  // Pour les mobiles
  return (
    <Router>
      <AppContainer>
        {isIOS && <IOSInstallPrompt />}
        {isAndroid && <AndroidInstallPrompt />}
      </AppContainer>
    </Router>
  );
};

export default App;
