import React from 'react';
import './IOSInstallPrompt.css';
import shareIconSafari from '../assets/iconShareiPhone.png';
import shareIconChrome from '../assets/iconShareiPhoneChrome.png';
import appLogo from '../assets/logo192.png';

const IOSInstallPrompt = () => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isChrome = /CriOS/.test(navigator.userAgent);
  const isSafari = /Safari/.test(navigator.userAgent) && !isChrome;
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

  console.log('📱 Navigateur iOS:', { isChrome, isSafari, userAgent: navigator.userAgent });

  // Ne pas afficher si déjà installé ou si ce n'est pas iOS
  if (isStandalone || !isIOS) {
    return null;
  }

  return (
    <div className="ios-install-prompt">
      <div className="ios-install-content">
        <img src={appLogo} alt="Hype Vap" className="app-logo" />
        <div className="install-header">
          <h2>Installez l'application</h2>
          <p>Pour une meilleure expérience et recevoir les notifications</p>
        </div>
        
        {isChrome ? (
          <div className="install-steps chrome">
            <div className="step">
              <div className="step-header">
                <span className="step-number">1</span>
                <div className="step-text-container">
                  <span>Appuyez sur</span>
                  <img src={shareIconChrome} alt="Menu" className="ios-icon" />
                  <span>en haut à droite</span>
                </div>
              </div>
            </div>
            <div className="step">
              <div className="step-header">
                <span className="step-number">2</span>
                <span>Faites défiler vers le bas et sélectionnez "Ajouter à l'écran d'accueil"</span>
              </div>
            </div>
            <div className="step">
              <div className="step-header">
                <span className="step-number">3</span>
                <span> Puis appuyez sur "Ajouter" pour confirmer</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="install-steps safari">
            <div className="step">
              <div className="step-header">
                <span className="step-number">1</span>
                <div className="step-text-container">
                  <span>Appuyez sur</span>
                  <img src={shareIconSafari} alt="Partager" className="ios-icon" />
                  <span>en bas de l'écran</span>
                </div>
              </div>
            </div>
            <div className="step">
              <div className="step-header">
                <span className="step-number">2</span>
                <span>Faites défiler vers le bas et appuyez sur "Sur l'écran d'accueil"</span>
              </div>
            </div>
            <div className="step">
              <div className="step-header">
                <span className="step-number">3</span>
                <span>Appuyez sur "Ajouter" en haut à droite pour confirmer</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IOSInstallPrompt; 