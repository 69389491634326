import React, { useState, useEffect } from 'react';
import SwitchToggle from '../components/SwitchToggle';
import NotificationButton from '../components/NotificationButton';
import { trackError } from '../services/errorTracking';
import './Notifications.css';

const Notifications = () => {
  const [permission, setPermission] = useState(null);
  
  useEffect(() => {
    const checkPermission = async () => {
      try {
        const currentPermission = await window.OneSignal.getNotificationPermission();
        setPermission(currentPermission);
      } catch (error) {
        console.error('Erreur vérification permission:', error);
      }
    };
    
    checkPermission();
    
    // Écouter les changements de permission
    window.OneSignal.on('notificationPermissionChange', checkPermission);
    return () => window.OneSignal.off('notificationPermissionChange', checkPermission);
  }, []);

  return (
    <div className="notifications-page">
      <div className="notifications-container">
        {permission === 'granted' ? (
          <SwitchToggle />
        ) : (
          <NotificationButton permission={permission} />
        )}
        {/* Liste des notifications à venir */}
      </div>
    </div>
  );
};

export default Notifications; 