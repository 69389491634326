import React, { useEffect, useState, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import './ShopsMap.css';
import logoMarker from '../assets/logo192.png'; // Import du logo
import L from 'leaflet';

// Styles de cartes disponibles (vous pouvez choisir celui que vous préférez)
const mapStyles = {
  // Style moderne et épuré
  modern: {
    url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  },
};

const getCustomIcon = (isSelected) => {
  const size = isSelected ? 40 : 32;
  return new Icon({
    iconUrl: logoMarker,
    iconSize: [size, size],
    iconAnchor: [size/2, size],
    className: `shop-marker ${isSelected ? 'selected' : ''}`
  });
};

// Icône pour la position de l'utilisateur
const userIcon = new Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  shadowSize: [41, 41]
});

const getCoordinates = (shop) => {
  if (shop.coordinates && shop.coordinates.latitude && shop.coordinates.longitude) {
    return [shop.coordinates.latitude, shop.coordinates.longitude];
  }
  return null;
};

const ShopsMap = ({ shops, selectedShop, onShopSelect }) => {
  const defaultCenter = [43.61632861198915, 1.384128057562548];
  const defaultZoom = 11;

  const handleMarkerClick = useCallback((shop) => {
    if (onShopSelect) {
      onShopSelect(shop);
    }
  }, [onShopSelect]);

  return (
    <div className="shops-map">
      <MapContainer 
        center={defaultCenter} 
        zoom={defaultZoom} 
        zoomControl={false}
        attributionControl={false}
      >
        <TileLayer
          url={mapStyles.modern.url}
          attribution={mapStyles.modern.attribution}
        />
        
        {shops.map(shop => {
          const coordinates = getCoordinates(shop);
          if (!coordinates) return null;

          return (
            <Marker 
              key={shop.id} 
              position={coordinates}
              icon={getCustomIcon(selectedShop?.id === shop.id)}
              eventHandlers={{
                click: () => handleMarkerClick(shop)
              }}
            />
          );
        })}
      </MapContainer>
    </div>
  );
};

export default ShopsMap; 